<template>
  <el-row class="footer hidden-md-and-down">
    <el-col class="info">
      <div class="navs">
        <div>
          {{ $t("message.product") }}
          <div class="second">
            <div @click="$router.push('/productAndService/hardware')">
              {{ $t("message.productTitle2") }}
            </div>

            <div style="margin: 8px 0"></div>
            <div @click="$router.push('/productAndService/electronic')">
              {{ $t("message.productTitle1") }}
            </div>
          </div>
        </div>
        <div class="first" @click="$router.push('/news')">
          {{ $t("message.news") }}
        </div>
        <div class="first" @click="$router.push('/support')">
          {{ $t("message.support") }}
        </div>
        <div class="first" @click="$router.push('/about')">
          {{ $t("message.about") }}
        </div>
        <div class="first" @click="$router.push('/contact')">
          {{ $t("message.contact") }}
        </div>
      </div>
    </el-col>
  </el-row>

  <el-row class="footer-bottom">
    <el-col :xs="24" :sm="6" class="item">
      ©{{ currentYear }} {{ $t("footer.copyright") }} </el-col><br />

    <el-col :xs="24" :sm="6" class="item">
      <a class="beian" @click.prevent="go()" rel="nofollow">粤ICP备2023058679号</a>
      <!-- &nbsp;
            &nbsp; -->
    </el-col>

    <el-col :xs="24" :sm="6" class="item">
      <img src="@/assets/备案图标.png" style="vertical-align: middle" />

      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602007857" class="beian">
        粤公网安备44030602007857号
      </a>
    </el-col>

    <el-col :xs="24" :sm="6" class="item">
      {{ $t("footer.version") }}: V{{ version }}
    </el-col>
  </el-row>
</template>

<script>

export default {
  setup() {
    function go() {
      // window.location.href = 'https://beian.miit.gov.cn'
      window.open("https://beian.miit.gov.cn", "_blank");
    }
    let currentYear = new Date().getFullYear();
    // const version = VUE_APP_Version
    const version = "1.1.8";

    // console.log(import.meta);
    return {
      go,
      version,
      currentYear
    };
  },
};
</script>

<style lang="less" scoped>
* {
  color: balck;
}

.beian {
  cursor: pointer;
  color: rgb(31, 106, 204);
}

// .third{
//     margin: 10px;
//     color: #6d6d6d;

//     li{
//         padding: 4px 0;

//     }
// }
.second {
  // display: flex;
  margin: 8px;

  div {
    cursor: pointer;

    &:hover {
      color: #b129e7;
    }
  }
}

.footer {
  padding: 1rem;
  background: #f2f2f2;
}

.first {
  cursor: pointer;

  &:hover {
    color: #b129e7;
  }
}

.footer-bottom {
  text-align: center;
  padding: 0.5rem;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;

  // font-size: 16px;
  .item {
    font-size: 0.6rem;
    margin: 4px 0;
  }
}

@media screen and (max-width: 450px) {
  .item {
    font-size: 1rem !important;
    // margin: 4px 0;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navs {
  width: 100%;
  display: flex;
  justify-content: space-around;

  span {
    cursor: pointer;
  }
}

.wechatImg {
  width: 150px;
  height: 150px;
}
</style>
