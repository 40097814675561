import { prefix } from "./newsdata";
export var productData = [
  {
    id: "ai",
    title: "微型嵌入式人工智能核心板",
    keyFeatures: ["Cube.AI", "丰富的接口", "小尺寸"],
    features: [
      {
        info: "Cortex-M4系列",
        subInfo: [],
      },
      {
        info: "支持Keras,TFLite,ONNX等深度学习框架",
        subInfo: [],
      },
      {
        info: "板载精准的基准源0.5%,VREF= 1.24V",
        subInfo: [],
      },
      {
        info: "支持FATFS文件系统",
        subInfo: [],
      },
      {
        info: "支持实时操作系统FreeRTOS,RT-Thread,zephyr",
        subInfo: [],
      },
      {
        info: "抗干扰能力强",
        subInfo: [],
      },
      {
        info: "支持RS-485,RS-232,I2C,USART,SPI,USB,I2S等总线",
        subInfo: [],
      },
      {
        info: "支持随机数生成",
        subInfo: [],
      },
    ],
    name: "coreBoard",
    details: [
      {
        input: `通过Vin引脚供电,+5~12V`,
        // model: `STM32G431CBT6`,
        // size: "43.2 mm * 19.06 mm",
        core: `ARM Cortex-M4`,
        flash: "128KB",
        RAM: "32KB | 22KB",
        pn: "ZZHY2023022301 | ZZHY2023022301F",
        max_freq: "170MHz",
        download: "ba14e59a-cff2-4627-99a5-19310964837b-01-cn",
      },
    ],
    img: [
      {
        src: prefix + "3c2b6713-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "ba14e59a-cff2-4627-99a5-19310964837b-01-cn",
    //   },
    // },
  },
  {
    id: "4g",
    title: "LoRa-4G网关",
    keyFeatures: ["MQTT云端互连", "低功耗设计", "LoRa组网 "],
    features: [
      {
        info: "本地和云端灵活配置",
        subInfo: [],
      },
      {
        info: "定位：北斗/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "内嵌实时操作系统",
        subInfo: [],
      },
      {
        info: "支持RS-485、RS-232、UART、I2C、SPI、USB2.0、模拟音频、数字音频、ADC",
        subInfo: [],
      },
      {
        info: "支持固件空中升级",
        subInfo: [],
      },
      {
        info: "支持屏幕显示",
        subInfo: [],
      },
    ],
    name: "gateway",
    details: [
      {
        type: "带外壳",
        // input: `内部电池供电或外部直流供电`,
        // fre_4g: "LTE-TDD：B34/B38/B39/B40/B41",
        // fre_lora: "470M～510MHz 或 860～930MHz",
        PN: "<div>带定位:ZZHY2024110706 | ZZHY2024110706F</div><div>不带定位:ZZHY2024110707 | ZZHY2024110707F</div>",
        // input: `内部电池供电 | 外部直流供电`,
        fre: "<div>4G:LTE-TDD：B34/B38/B39/B40/B41</div><div>LoRa：470M～510MHz或860～930MHz</div>",
        size: "133mm * 75.89mm * 48.09mm",

        download: "ba14e59a-cff2-6666-99a5-19310964837b-01-cn",
      },
      {
        type: "不带外壳",
        // input: `内部电池供电或外部直流供电`,
        PN: "<div>带定位:ZZHY2024082401 | ZZHY2024082401F</div><div>不带定位:ZZHY2024082402 | ZZHY2024082402F</div>",
        // input: `内部电池供电 | 外部直流供电`,
        fre: "<div>4G:LTE-TDD：B34/B38/B39/B40/B41</div><div>LoRa：470M～510MHz或860～930MHz</div>",
        size: "75mm × 50mm × 13mm",
        // fre_4g: "LTE-TDD：B34/B38/B39/B40/B41",
        // fre_lora: "470M～510MHz 或 860～930MHz",
        download: "ba14e59a-cff2-6666-99a5-19339864837b-01-cn",
      },
    ],
    img: [
      {
        // src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896www-03-xx.png",
        src: prefix + "3c2bwe13-b963-43c4-a8a0-73c3da796www-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "ba14e59a-cff2-6666-99a5-19310964837b-01-cn",
    //   },
    // },
  },
  {
    id: "xxwl",
    title: "小型物联网终端板",
    keyFeatures: [
      "支持WIFI和蓝牙",
      "内置六轴加速度和温湿度传感器",
      "支持USB Type-C",
    ],
    features: [
      {
        info: "主模块ESP32系列",
        subInfo: [],
      },
      {
        info: "支持UART,I2C,SPI等多种总线协议",
        subInfo: [],
      },
      {
        info: "支持OTA",
        subInfo: [],
      },
      {
        info: "支持通过USB,Type-C接口烧录程序",
        subInfo: [],
      },
      {
        info: "丰富的GPIO接口,共26个",
        subInfo: [],
      },
      {
        info: "可用于Web Server",
        subInfo: [],
      },
      {
        info: "支持实时操作系统FreeRTOS",
        subInfo: [],
      },
      {
        info: "良好的PCB设计使得天线发射和接收性能最佳",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [
      {
        input: `方式一:USB Type-C供电   方式二:Vin引脚5V供电 `,
        // model: `ESP32-WROOM-32`,
        flash: "4MB",
        SRAM: "520KB",
        pn: "ZZHY2022120901 | ZZHY2022120901F",
        download: "1c98b919-e5bb-4023-8f66-d77078708372-01-cn",
      },
    ],
    img: [
      {
        src: prefix + "a9c6405d-d11e-438b-8ab7-c29be2cfa51c-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "1c98b919-e5bb-4023-8f66-d77078708372-01-cn",
    //   },
    // },
  },
  {
    id: "jyh",
    title: '"机遇号"物联网终端核心板',
    keyFeatures: [
      "强大的处理能力",
      "丰富的IO接口(136pins)",
      "内嵌物联网通信板",
    ],
    features: [
      {
        info: "Cortex-M4系列",
        subInfo: [],
      },
      {
        info: "支持Keras,TFLite,ONNX等深度学习框架",
        subInfo: [],
      },
      {
        info: "支持TFT屏幕显示",
        subInfo: [],
      },
      {
        info: "实时操作系统FreeRTOS,RT-Thread,zephyr",
        subInfo: [],
      },
      {
        info: "支持WIFI通信",
        subInfo: [],
      },
      {
        info: "丰富的GPIO接口(136pins)",
        subInfo: [],
      },
      {
        info: "支持RS-485,USART,I2C,SPI,USB等总线",
        subInfo: [],
      },
      {
        info: "7.2Msps ADC",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [
      {
        input: `+3.3V~+12V `,
        // model: `ESP32-WROOM-32`,
        flash: "1MB | 4Gb | 256Mb",
        RAM: "(256KB + 4KB) 256Mb",
        pn: "ZZHY2022120901 | ZZHY2022120901F",
        core: "ARM Cortex-M4",
        max_freq: "180MHz",
        download: "666040b9-1a64-4982-a246-91356ed928b9-01-cn",
      },
    ],
    img: [
      {
        src: prefix + "a9c64123-d11e-438b-8ab7-c29be2cfa52a-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "666040b9-1a64-4982-a246-91356ed928b9-01-cn",
    //   },
    // },
  },
  {
    id: 3,
    title: "智慧物联网后台管理系统",
    keyFeatures: ["实时数据", "人工智能", "数据库集群"],
    features: [
      {
        info: "边缘计算和云计算的有机结合,AI算法实现推理和决策",
        subInfo: [],
      },
      {
        info: "云端算法投送,按需动态分配,助力边缘AI",
        subInfo: [],
      },
      {
        info: "数据库集群",
        subInfo: [],
      },
      {
        info: "自动化测试优化产品质量",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "c747d053-0baf-425e-8202-a72999054e7e-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "4b663419-4077-479f-9334-bf159509ecac-01-cn",
      },
    },
  },
  {
    id: 4,
    title: "数字天工-智慧工厂",
    keyFeatures: ["虚实结合", "数字演绎", "AI赋能"],
    features: [
      {
        info: "虚实结合",
        subInfo: [],
      },
      {
        info: "数字演绎",
        subInfo: [],
      },
      {
        info: "AI赋能",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "5d654ae2-8877-493e-b47e-be02d9f67a3b-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "379987fc-3663-46b2-a191-22d6d7b517c8-01-cn",
      },
    },
  },
  ///new !
  {
    id: 5,
    title: "4G终端",
    keyFeatures: ["边云协作", "宽温应用", "卫星定位"],
    features: [
      {
        info: "LTE Cat-1/TDD",
        subInfo: [],
      },
      {
        info: "工作温度:-30­°C~75­°C",
        subInfo: [],
      },
      {
        info: "加密传输",
        subInfo: [],
      },
      {
        info: "定位：北斗/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "外壳IP65防水防尘",
        subInfo: [],
      },
      {
        info: "可支持定制国际物联网卡",
        subInfo: [],
      },
    ],
    name: "小型4G终端",
    details: [
      {
        type: "带外壳",
        PN: "<div>带定位:ZZHY2024110701 | ZZHY2024110701F</div><div>不带定位:ZZHY2024110702 | ZZHY2024110702F</div>",
        // input: `内部电池供电 | 外部直流供电`,
        fre: "<div>4G:LTE-TDD：B34/B38/B39/B40/B41</div>",
        size: `133mm * 75.89mm * 48.09mm`,
        download: "163b6afa-a371-49c4-86de-48fafc847536-01-cn",
      },
      {
        type: "不带外壳",
        PN: "<div>带定位:ZZHY2023111101 | ZZHY2023111101F</div><div>不带定位:ZZHY2023111102 | ZZHY2023111102F</div>",
        // input: `5～12V供电`,
        fre: "<div>4G:LTE-TDD：B34/B38/B39/B40/B41</div>",
        size: `45mm * 45mm * 9mm`,
        download: "163b6afa-a371-49c4-86de-48fafc847538-01-cn",
      },
    ],
    img: [
      {
        src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "163b6afa-a371-49c4-86de-48fafc847536-01-cn",
    //   },
    // },
  },
  {
    id: 6,
    title: "NB-IoT终端",
    keyFeatures: ["边云协作", "宽温应用", "低功耗设计"],
    features: [
      {
        info: "5G NB-IoT",
        subInfo: [],
      },
      {
        info: "工作温度:-40­°C~85­°C",
        subInfo: [],
      },
      {
        info: "支持实时操作系统",
        subInfo: [],
      },
      {
        info: "支持固件空中升级",
        subInfo: [],
      },
      {
        info: "外壳IP65防水防尘",
        subInfo: [],
      },
      {
        info: "支持RS-485、I2C、SPI、ADC等",
        subInfo: [],
      },
      // {
      //   info: "频段：B5/B8",
      //   subInfo: [],
      // },
      // {
      //   info: "数据传输：Cat NB2速率：126kbps（DL）/ 158.5kbps（UL）",
      //   subInfo: [],
      // },
      // {
      //   info: "网络协议：MQTT / HTTP / TCP / UDP / CoAP / LwM2M / IPV6 / DTLS / DNS",
      //   subInfo: [],
      // },
    ],
    name: "NB-IoT终端",
    details: [
      {
        type: "带外壳",
        PN: "<div>ZZHY2024110703 | ZZHY2024110703F</div>",

        // input: `内部电池供电或外部直流供电`,
        fre: "B5/B8",
        size: `133mm * 75.89mm * 48.09mm`,
        download: "e701af55-15a7-4aab-bbcf-5c6031412fcc-01-cn",
        // core: "Cortex-M0+",
      },
      {
        type: "不带外壳",
        PN: "<div>ZZHY2023060601 | ZZHY2023060601F</div>",

        // input: `方式一:5V供电   方式二:锂电池供电`,
        fre: "B5/B8",
        size: `25mm * 25mm * 6mm`,
        download: "e701af55-15a7-4aab-bbcf-5c6031412fdd-01-cn",
        // core: "Cortex-M0+",
      },
    ],
    img: [
      {
        // src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
        src: prefix + "1438f644-006e-46b6-b644-5078ae4122c3-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "e701af55-15a7-4aab-bbcf-5c6031412fcc-01-cn",
    //   },
    // },
  },
  {
    id: 7,
    title: "LoRa终端",
    keyFeatures: ["LPWAN", "低功耗设计", "宽温应用"],
    features: [
      {
        info: "低频段或高频段",
        subInfo: [],
      },
      {
        info: "工作温度:-40­°C~85­°C",
        subInfo: [],
      },
      {
        info: "外壳IP65防水防尘",
        subInfo: [],
      },
      {
        info: "加密传输",
        subInfo: [],
      },
      {
        info: "支持固件空中升级",
        subInfo: [],
      },
      {
        info: "自由组网",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [
      {
        type: "带外壳",
        // input: `内部电池供电或外部直流供电`,
        PN: "<div>ZZHY2024110704 | ZZHY2024110704F</div>",
        size: `133mm * 75.89mm * 48.09mm`,
        fre: "410M～510MHz或860MHz～930MHz",
        download: "58e893f1-dd71-40a2-8f84-5a11e4c0afee-01-cn",
        // core: "Cortex-M0+",
        // flash: "32KB",
        // RAM: "8KB",
        // maxPin: "32MHz",
        // eeprom: "1KB",
        // pn: "ZZHY2024110704 | WAU2023042601F",
      },
      {
        type: "不带外壳",
        // input: `方式一:5~12V供电   方式二:锂电池供电`,
        PN: "<div>ZZHY2023042601 | ZZHY2023042601F</div>",
        size: `35mm × 35mm × 13.7mm`,
        fre: "410M～510MHz或860MHz～930MHz",
        download: "58e893f1-dd71-40a2-8f84-5a11e4c0afff-01-cn",

        // core: "Cortex-M0+",
      },
    ],
    img: [
      {
        src: prefix + "1e9b6567-f715-4db9-bc4e-4f443ba6e6a8-03-xx.png",
        // src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "58e893f1-dd71-40a2-8f84-5a11e4c0afee-01-cn",
    //   },
    // },
  },
  // 机器视觉
  {
    id: 8,
    title: "安防应用",
    keyFeatures: [],
    features: [
      {
        info: "安全帽、安全衣检测",
        subInfo: [],
      },
      {
        info: "明火检测",
        subInfo: [],
      },
      {
        info: "人脸识别",
        subInfo: [],
      },
      {
        info: "人形识别",
        subInfo: [],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "46fa6615-ab74-4df3-a6c4-3050338244e6-03-xx.png",
      },
    ],
    // download: "",
    download: {
      cn: {
        file_code: "",
      },
    },
  },
  {
    id: 9,
    title: "工业检测",
    keyFeatures: [],
    features: [
      {
        info: "产品外观不良检测",
        subInfo: ["高精度", "稳定", "实时"],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "0e16b67f-853e-4571-9139-123231f4298f-03-xx.png",
      },
    ],
    // download: "",
    download: {
      cn: {
        file_code: "",
      },
    },
  },
  {
    id: 10,
    title: "TurMass™终端",
    keyFeatures: ["低功耗广域网", "全国产方案", "宽温应用 "],
    features: [
      {
        info: "基于道生物联全国产终端模组设计",
        subInfo: [],
      },
      {
        info: "自由频段",
        subInfo: [],
      },
      {
        info: "加密传输",
        subInfo: [],
      },
      {
        info: "外壳IP65防水防尘",
        subInfo: [],
      },
      {
        info: "RISC-V内核",
        subInfo: [],
      },
      {
        info: "工作温度:-40­°C~85­°C",
        subInfo: [],
      },
      {
        info: "注:TurMass™为上海道生物联技术有限公司的商标",
        subInfo: [],
      },
    ],
    name: "gateway",
    details: [
      {
        // input: `内部电池供电或外部直流供电`,
        type: "带外壳",
        PN: "<div>ZZHY2024110705 | ZZHY2024110705F</div>",
        fre: "低频(470MHz~510MHz)或高频(863MHz~928MHz)",
        size: "133mm * 75.89mm * 48.09mm",
        // rate: "0.441kbps~85.106kbps",
        // distance: "户外测试:4.2 千米",
        download: "ba14e59a-cff2-1234-99a5-19310964837b-01-cn",
      },
      {
        // input: `内部电池供电或外部直流供电`,
        type: "不带外壳",
        PN: "<div>ZZHY2024111801 | ZZHY2024111801F</div>",
        fre: "低频(470MHz~510MHz)或高频(863MHz~928MHz)",
        size: "30mm * 30mm * 11.7mm",

        // rate: "0.441kbps~85.106kbps",
        // distance: "户外测试:4.2 千米",
        download: "ba14e59a-cff2-1234-66a5-19310964837b-01-cn",
      },
    ],
    img: [
      {
        // src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
        src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "ba14e59a-cff2-1234-99a5-19310964837b-01-cn",
    //   },
    // },
  },
  {
    id: 11,
    title: "TurMass™—4G网关",
    keyFeatures: ["异构协作", "全国产方案", "宽温应用 "],
    features: [
      {
        info: "基于道生物联全国产终端模组设计",
        subInfo: [],
      },
      {
        info: "工作温度:-30­°C~75­°C",
        subInfo: [],
      },
      {
        info: "加密传输",
        subInfo: [],
      },
      {
        info: "外壳IP65防水防尘",
        subInfo: [],
      },
      {
        info: "定位：北斗/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "可支持定制国际物联网卡",
        subInfo: [],
      },
      {
        info: "注:TurMass™为上海道生物联技术有限公司的商标",
        subInfo: [],
      },
    ],
    name: "gateway",
    details: [
      {
        input: `外部供电（5~15V）`,
        PN: "<div>ZZHY2024110708 | ZZHY2024110708F</div>",
        size: "133mm * 75.89mm * 48.09mm",
        fre: "<div>4G：LTE-TDD：B34/B38/B39/B40/B41；</div><div>TurMass：低频(470MHz~510MHz)或高频(863MHz~928MHz)</div>",
        download: "ba14e59a-cdd2-6666-99a5-19310964837b-01-cn",
      },
    ],
    img: [
      {
        src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896www-03-xx.png",
        // src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "ba14e59a-cdd2-6666-99a5-19310964837b-01-cn",
    //   },
    // },
  },
  {
    id: 12,
    title: "LoRa-以太网关",
    keyFeatures: ["异构协作", "自由组网", "宽温应用 "],
    features: [
      {
        info: "低频段或高频段",
        subInfo: [],
      },
      {
        info: "外壳IP65防水防尘",
        subInfo: [],
      },
      {
        info: "加密传输",
        subInfo: [],
      },
      {
        info: "内嵌实时操作系统",
        subInfo: [],
      },
    ],
    name: "gateway",
    details: [
      {
        input: `AC:220VAC`,
        PN: "<div>ZZHY2024110709 | ZZHY2024110709F</div>",
        size: "133mm * 75.89mm * 48.09mm",
        netrate: "10/100M",
        // fre: "LoRa：410M～525MHz 或 860～930MHz",
        LoRa_Receive_Sensitivity: "LoRa：-137 dBm@SF10 125KHz",
        download: "sn14e59a-cff2-6666-99a5-19310964837b-01-cn",
      },
    ],
    img: [
      {
        src: prefix + "449bwe13-b963-43c4-a8a0-73c324566e10-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "sn14e59a-cff2-6666-99a5-19310964837b-01-cn",
    //   },
    // },
  },
];

export var serviceData = [
  {
    id: 1,
    title: "电路设计",
    keyFeatures: [],
    features: [
      {
        info: "模拟/数字电路设计,PCB Layout,样品制作,包括：",
        subInfo: [
          "嵌入式系统(含通信模块)",
          "AC/DC电源,DC/DC电源",
          "锂电池储能系统",
        ],
      },
      {
        info: "三维系统建模",
        subInfo: [],
      },
      {
        info: "高性价比,可量产的BOM物料",
        subInfo: [],
      },
      {
        info: "第三方测试认证服务(EMC、Safety和可靠性)",
        subInfo: [],
      },
      {
        info: "高密度,小型化设计",
        subInfo: [],
      },
    ],
    name: "dianlu",
    details: [],
    img: [
      {
        src: prefix + "5b46c30d-4b0b-410b-b824-1281e3112aba-03-xx.jpg",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-cn",
      },
    },
  },
  {
    id: 2,
    title: "嵌入式软件开发",
    keyFeatures: [],
    features: [
      {
        info: "ARM Cortex-M系列(例如:STM32),包括:",
        subInfo: [
          "ADC/DAC",
          "UART,I2C,SPI,CAN,RS-232,RS-485,USB等各种总线通信",
          "实时操作系统FreeRTOS,RT-Thread等",
          "FATFS文件系统",
        ],
      },
      {
        info: "ARM Cortex-A系列",
        subInfo: [],
      },
      {
        info: "电池BMS算法",
        subInfo: [],
      },
      {
        info: "轻量化深度神经网络开发",
        subInfo: [],
      },
      {
        info: "固件空中升级（FOTA）",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [],
    img: [
      {
        src: prefix + "6faa16f6-bf6f-483d-afc1-6c39ef1261a9-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-cn",
      },
    },
  },
  {
    id: 3,
    title: "机械设计",
    keyFeatures: [],
    features: [
      {
        info: "ID设计",
        subInfo: [],
      },
      {
        info: "整机设计",
        subInfo: [],
      },
      {
        info: "2D/3D结构图",
        subInfo: [],
      },
      {
        info: "堆叠",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "c747d053-0baf-425e-8202-a72999054e6e-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-cn",
      },
    },
  },
  {
    id: 4,
    title: "桌面应用软件开发",
    keyFeatures: ["跨平台", "支持多种通信协议BLE、WIFI等", "实时波形图"],
    features: [
      {
        info: "跨平台（Windows、Linux、MacOs）",
        subInfo: [],
      },
      {
        info: "AI算法加持",
        subInfo: [],
      },
      {
        info: "模块自由加减",
        subInfo: [],
      },
      {
        info: "自适应布局",
        subInfo: [],
      },
      {
        info: "支持多种通信协议（BLE、WIFI等）",
        subInfo: [],
      },
      {
        info: "内嵌数据库",
        subInfo: [],
      },
      {
        info: "精美的UI设计",
        subInfo: [],
      },
      {
        info: "自动生成报表（PDF、Word、Excel等）",
        subInfo: [],
      },
      {
        info: "OTA升级",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "777c112b-83ca-4acf-8a98-4947ab5524da-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "91dc746c-3c01-437e-a6e1-e1ddc932d9ce-01-cn",
      },
    },
  },
  {
    id: 5,
    title: "前端开发",
    keyFeatures: ["前端开发", "数据大屏", "专业UI设计"],
    features: [
      {
        info: "实时性",
        subInfo: [],
      },
      {
        info: "全方位",
        subInfo: [],
      },
      {
        info: "GIS定位",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "7d0dd728-23fa-4e01-9e94-234809b8be78-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "a73372b9-7df0-46bc-b989-00131aa87380-01-cn",
      },
    },
  },
  {
    id: 6,
    title: "数据分析",
    keyFeatures: [],
    features: [
      {
        info: "数理统计",
        subInfo: [],
      },
      {
        info: "数据建模",
        subInfo: [],
      },
      {
        info: "数据挖掘",
        subInfo: [],
      },
      {
        info: "机器学习",
        subInfo: [],
      },
      {
        info: "大数据分析",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "4c40bf6d-c04e-4249-a032-e46e211bab3c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "a73372b9-7df0-46bc-b989-00131aa87380-01-cn",
      },
    },
  },
  {
    id: 7,
    title: "边缘计算网关",
    keyFeatures: ["AI计算", "边云协同", "高可靠性"],
    features: [
      {
        info: "AI算力",
        subInfo: [],
      },
      {
        info: "Linux系统",
        subInfo: [],
      },
      {
        info: "支持主流目标检测和图像识别算法",
        subInfo: [],
      },
      {
        info: "云端算法投送",
        subInfo: [],
      },
      {
        info: "4G、5G、Zigbee、LoRa",
        subInfo: [],
      },
      {
        info: "支持业内主流深度学习计算框架",
        subInfo: [],
      },
      {
        info: "支持Docker部署",
        subInfo: [],
      },
      {
        info: "任务编排",
        subInfo: [],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "4c40bf6d-c04e-4249-a032-e46e211www3c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "0e9040b9-1a64-4982-a246-91356ed928b9-01-cn",
      },
    },
  },
];

export var productData_en = [
  {
    id: "ai",
    title: "Mini Embedded AI Coreboard",
    keyFeatures: ["Cube.AI", "Rich interfaces", "Small Footprint"],
    features: [
      {
        info: "Cortex-M4 series",
        subInfo: [],
      },
      {
        info: "Support deep learning frameworks such as Keras, TFLite, ONNX",
        subInfo: [],
      },
      {
        info: "On-board precise reference source 0.5%,VREF = 1.24V",
        subInfo: [],
      },
      {
        info: "FATFS file system",
        subInfo: [],
      },
      {
        info: "Support real-time operating systems FreeRTOS, RT-Thread,zephyr",
        subInfo: [],
      },
      {
        info: "Electromagnetic Immunity",
        subInfo: [],
      },
      {
        info: "RS-485, RS-232, I2C, USART, SPI, USB, I2S and other buses",
        subInfo: [],
      },
      {
        info: "Random number generation",
        subInfo: [],
      },
    ],
    name: "coreBoard",
    details: [
      {
        input: `Powered by Vin pin, +5~12V`,
        // model: `STM32G431CBT6`,
        // size: "43.2 mm * 19.06 mm",
        core: `ARM Cortex-M4`,
        flash: "128KB",
        RAM: "32KB | 22KB",
        size: "43.2 mm * 19.06 mm",

        pn: "ZZHY2023022301 | ZZHY2023022301F",
        max_freq: "170MHz",
        download: "ba14e59a-cff2-4627-99a5-19310964837b-01-en",
      },
    ],
    img: [
      {
        src: prefix + "3c2b6713-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "ba14e59a-cff2-4627-99a5-19310964837b-01-en",
    //   },
    // },
  },
  {
    id: "4g",
    title: "LoRa-4G Gateway",
    keyFeatures: [
      "MQTT cloud interconnection",
      "low-power design",
      "LoRa networking",
    ],
    features: [
      {
        info: "Flexible configuration locally and cloud",
        subInfo: [],
      },
      {
        info: "Positioning: BeiDou/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "Embedded real-time operating system",
        subInfo: [],
      },
      {
        info: "Support RS-485, RS-232, UART, I2C, SPI, USB2.0, Analogue Audio, Digital Audio, ADC",
        subInfo: [],
      },
      {
        info: "Supports OTA",
        subInfo: [],
      },
      {
        info: "Support for on-screen display",
        subInfo: [],
      },
      // {
      //   info: "Support SD card local storage",
      //   subInfo: [],
      // },
    ],
    name: "gateway",
    details: [
      {
        type: "with shell",
        // input: `Internal battery power or external DC power`,
        // size: "133mm * 75.89mm * 48.09mm",
        // fre_4g: "LTE-TDD：B34/B38/B39/B40/B41",
        // fre_lora: "470M～510MHz or 860～930MHz",
        PN: "<div>with locator:ZZHY2024110706 | ZZHY2024110706F</div><div>without locator:ZZHY2024110707 | ZZHY2024110707F</div>",
        // input: `内部电池供电 | 外部直流供电`,
        fre: "<div>4G:LTE-TDD：B34/B38/B39/B40/B41</div><div>LoRa：470M～510MHz or 860～930MHz</div>",
        size: "133mm * 75.89mm * 48.09mm",

        download: "ba14e59a-cff2-6666-99a5-19310964837b-01-en",
      },
      {
        type: "without shell",
        // input: `Internal battery power or external DC power`,
        // size: "133mm * 75.89mm * 48.09mm",
        // fre_4g: "LTE-TDD：B34/B38/B39/B40/B41",
        // fre_lora: "470M～510MHz or 860～930MHz",
        PN: "<div>with locator:ZZHY2024082401 | ZZHY2024082401F</div><div>without locator:ZZHY2024082402 | ZZHY2024082402F</div>",
        // input: `内部电池供电 | 外部直流供电`,
        fre: "<div>4G:LTE-TDD：B34/B38/B39/B40/B41</div><div>LoRa：470M～510MHz or 860～930MHz</div>",
        size: "75mm × 50mm × 13mm",
        // fre_4g: "LTE-TDD：B34/B38/B39/B40/B41",
        // fre_lora: "470M～510MHz 或 860～930MHz",
        download: "ba14e59a-cff2-6666-99a5-19339864837b-01-en",
      },
    ],
    img: [
      {
        src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "ba14e59a-cff2-6666-99a5-19310964837b-01-en",
    //   },
    // },
  },
  {
    id: "xxwl",
    title: "Small IoT Terminal Board",
    keyFeatures: [
      "WIFI and Bluetooth",
      "Built-in six-axis acceleration、temperature and humidity sensors",
      "USB Type-C",
    ],
    features: [
      {
        info: "Main module: ESP32 series",
        subInfo: [],
      },
      {
        info: "Supports multiple bus protocols,such as UART, I2C, SPI",
        subInfo: [],
      },
      {
        info: "OTA",
        subInfo: [],
      },
      {
        info: "Burning program via USB Type-C interface",
        subInfo: [],
      },
      {
        info: "Rich GPIO interfaces, a total of 26",
        subInfo: [],
      },
      {
        info: "Can be used for Web Server",
        subInfo: [],
      },
      {
        info: "Real-time operating system FreeRTOS",
        subInfo: [],
      },
      {
        info: "Good PCB design makes the antenna Optimal transmit and receive performance",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [
      {
        input: `Methods 1: USB Type-C power supply   
        Methods 2: Vin pin 5V power supply   `,
        model: `ESP32-WROOM-32`,
        flash: "4MB",
        SRAM: "520KB",
        pn: "ZZHY2022120901 | ZZHY2022120901F",
        download: "1c98b919-e5bb-4023-8f66-d77078708372-01-en",
      },
    ],
    img: [
      {
        src: prefix + "a9c6405d-d11e-438b-8ab7-c29be2cfa51c-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "1c98b919-e5bb-4023-8f66-d77078708372-01-en",
    //   },
    // },
  },
  {
    id: "jyh",
    title: '"Opportunity" IoT terminal core boards',
    keyFeatures: [
      "powerful processing capabilities",
      "Rich IO interface (136pins)",
      "Embedded IoT communication board",
    ],
    features: [
      {
        info: "Cortex-M4 series",
        subInfo: [],
      },
      {
        info: "Support Keras, TFLite, ONNX and other deep learning frameworks",
        subInfo: [],
      },
      {
        info: "Support TFT screen",
        subInfo: [],
      },
      {
        info: "Real-time operating system FreeRTOS,RT-Thread,zephyr",
        subInfo: [],
      },
      {
        info: "Supports WIFI",
        subInfo: [],
      },
      {
        info: "Rich IO interface (136pins)",
        subInfo: [],
      },
      {
        info: "Support RS-485, USART, I2C, SPI, USB and other buses.",
        subInfo: [],
      },
      {
        info: "7.2Msps ADC",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [
      {
        input: `+3.3V~+12V `,
        // model: `ESP32-WROOM-32`,
        flash: "1MB | 4Gb | 256Mb",
        RAM: "(256KB + 4KB) 256Mb",
        pn: "ZZHY2022120901 | ZZHY2022120901F",
        core: "ARM Cortex-M4",
        max_freq: "180MHz",
        download: "666040b9-1a64-4982-a246-91356ed928b9-01-en",
      },
    ],
    img: [
      {
        src: prefix + "a9c64123-d11e-438b-8ab7-c29be2cfa52a-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "666040b9-1a64-4982-a246-91356ed928b9-01-en",
    //   },
    // },
  },

  {
    id: 3,
    title: "Intelligent IOT Console System",
    keyFeatures: ["Real-time data", "AI", "Database clustering"],
    features: [
      {
        info: "The organic combination of edge computing and cloud computing, AI algorithms to achieve reasoning and decision-making.",
        subInfo: [],
      },
      {
        info: "Cloud algorithm delivery,on-demand dynamic distribution, to help edge AI.",
        subInfo: [],
      },
      {
        info: "Database clustering",
        subInfo: [],
      },
      {
        info: "Automated testing to optimise product quality",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [
      {
        input: `方式一:USB Type-C供电; 方式二:Vin引脚5V供电`,
        model: `ESP32-WROOM-32`,
        flash: "4MB",
        SRAM: "520KB",
      },
    ],
    img: [
      {
        src: prefix + "c747d053-0baf-425e-8202-a72999054e7e-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "4b663419-4077-479f-9334-bf159509ecac-01-en",
      },
    },
  },
  {
    id: 4,
    title: "Digital Skyworks-Smart Factory",
    keyFeatures: [
      "Combination of reality and imagination",
      "Digital Interpretation",
      "AI Empowerment",
    ],
    features: [
      {
        info: "Combination of reality and imagination",
        subInfo: [],
      },
      {
        info: "Digital Interpretation",
        subInfo: [],
      },
      {
        info: "AI Empowerment",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "5d654ae2-8877-493e-b47e-be02d9f67a3b-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "379987fc-3663-46b2-a191-22d6d7b517c8-01-en",
      },
    },
  },
  ///new !
  {
    id: 5,
    title: "4G Terminal",
    keyFeatures: [
      "Edge-cloud collaboration",
      "Wide Temperature Applications",
      "Satellite Positioning",
    ],
    features: [
      {
        info: "LTE Cat-1/TDD",
        subInfo: [],
      },
      {
        info: "Working temperature:-30°C~75°C",
        subInfo: [],
      },
      {
        info: "encrypted transmission",
        subInfo: [],
      },
      {
        info: "Positioning: BeiDou/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "Shell:IP65 waterproof and dustproof",
        subInfo: [],
      },
      {
        info: "Customised IoT cards can be supported",
        subInfo: [],
      },
    ],
    name: "小型4G终端",
    details: [
      // {
      //   input: `Internal battery power | External DC power`,
      //   size: `133mm * 75.89mm * 48.09mm`,
      // },
      {
        type: "with shell",
        PN: "<div>with locator:ZZHY2024110701 | ZZHY2024110701F</div><div>without locator:ZZHY2024110702 | ZZHY2024110702F</div>",
        // input: `内部电池供电 | 外部直流供电`,
        fre: "<div>4G:LTE-TDD：B34/B38/B39/B40/B41</div>",
        size: `133mm * 75.89mm * 48.09mm`,
        download: "163b6afa-a371-49c4-86de-48fafc847536-01-en",
      },
      {
        type: "without shell",
        PN: "<div>with locator:ZZHY2023111101 | ZZHY2023111101F</div><div>without locator:ZZHY2023111102 | ZZHY2023111102F</div>",
        // input: `5～12V供电`,
        fre: "<div>4G:LTE-TDD：B34/B38/B39/B40/B41</div>",
        size: `45mm * 45mm * 9mm`,
        download: "163b6afa-a371-49c4-86de-48fafc847538-01-en",
      },
    ],
    img: [
      {
        src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "163b6afa-a371-49c4-86de-48fafc847536-01-en",
    //   },
    // },
  },
  {
    id: 6,
    title: "NB-IoT Terminal",
    keyFeatures: [
      "Side-cloud Collaboration",
      "Wide Temperature Applications",
      "Low-power Design",
    ],
    features: [
      {
        info: "5G NB-IoT",
        subInfo: [],
      },
      {
        info: "Working temperature:-30°C~75°C",
        subInfo: [],
      },
      {
        info: "Support for real-time operating systems",
        subInfo: [],
      },

      {
        info: "Supports OTA",
        subInfo: [],
      },
      {
        info: `Shell:IP65 waterproof and dustproof`,
        subInfo: [],
      },

      {
        info: "Support RS-485, I2C, SPI, ADC, etc",
        subInfo: [],
      },
      // {
      //   info: "Firmware over-the-air upgrade",
      //   subInfo: [],
      // },
      // {
      //   info: "Frequency band：B5/B8",
      //   subInfo: [],
      // },
      // {
      //   info: "Data transfer：Cat NB2 Rate：126kbps（DL）/ 158.5kbps（UL）",
      //   subInfo: [],
      // },
      // {
      //   info: "Network protocols：MQTT / HTTP / TCP / UDP / CoAP / LwM2M / IPV6 / DTLS / DNS",
      //   subInfo: [],
      // },
    ],
    name: "微型NB-IoT终端",
    details: [
      // {
      //   input: `Internal battery power | External DC power`,
      //   fre: "B5/B8",
      //   size: `133mm * 75.89mm * 48.09mm`,
      //   // core: "Cortex-M0+",
      //   // flash: "32KB",
      //   // RAM: "8KB",
      //   // maxPin: "32MHz",
      //   // eeprom: "1KB",
      //   // pn: "ZZHY2024110703 | WAU2023060602F",
      // },
      {
        type: "with shell",
        PN: "<div>ZZHY2024110703 | ZZHY2024110703F</div>",

        // input: `内部电池供电或外部直流供电`,
        fre: "B5/B8",
        size: `133mm * 75.89mm * 48.09mm`,
        download: "e701af55-15a7-4aab-bbcf-5c6031412fcc-01-en",
        // core: "Cortex-M0+",
      },
      {
        type: "without shell",
        PN: "<div>ZZHY2023060601 | ZZHY2023060601F</div>",

        // input: `方式一:5V供电   方式二:锂电池供电`,
        fre: "B5/B8",
        size: `25mm * 25mm * 6mm`,
        download: "e701af55-15a7-4aab-bbcf-5c6031412fdd-01-en",
        // core: "Cortex-M0+",
      },
    ],
    img: [
      {
        src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "e701af55-15a7-4aab-bbcf-5c6031412fcc-01-en",
    //   },
    // },
  },
  {
    id: 7,
    title: "LoRa Terminal",
    keyFeatures: ["LPWAN", "Low-power Design", "Wide temperature applications"],
    features: [
      {
        info: "Low or high frequency band",
        subInfo: [],
      },
      {
        info: "Operating Temperature:-40°C~85°C",
        subInfo: [],
      },
      {
        info: "Shell:IP65 waterproof and dustproof",
        subInfo: [],
      },
      {
        info: `Encrypted transmission`,
        subInfo: [],
      },
      {
        info: "Support OTA",
        subInfo: [],
      },
      {
        info: "Free Networking",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [
      // {
      //   input: `Internal battery power or external DC power`,
      //   size: `133mm * 75.89mm * 48.09mm`,
      //   fre: "410M～510MHz或860MHz～930MHz",

      //   // core: "Cortex-M0+",
      //   // flash: "32KB",
      //   // RAM: "8KB",
      //   // maxPin: "32MHz",
      //   // eeprom: "1KB",
      //   // pn: "ZZHY2024110704 | WAU2023042601F",
      // },
      {
        type: "with shell",
        // input: `内部电池供电或外部直流供电`,
        PN: "<div>ZZHY2024110704 | ZZHY2024110704F</div>",
        size: `133mm * 75.89mm * 48.09mm`,
        fre: "410M～510MHz or 860MHz～930MHz",
        download: "58e893f1-dd71-40a2-8f84-5a11e4c0afee-01-en",
        // core: "Cortex-M0+",
        // flash: "32KB",
        // RAM: "8KB",
        // maxPin: "32MHz",
        // eeprom: "1KB",
        // pn: "ZZHY2024110704 | WAU2023042601F",
      },
      {
        type: "without shell",
        // input: `方式一:5~12V供电   方式二:锂电池供电`,
        PN: "<div>ZZHY2023042601 | ZZHY2023042601F</div>",
        size: `35mm × 35mm × 13.7mm`,
        fre: "410M～510MHz or 860MHz～930MHz",
        download: "58e893f1-dd71-40a2-8f84-5a11e4c0afff-01-en",

        // core: "Cortex-M0+",
      },
    ],
    img: [
      {
        src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "58e893f1-dd71-40a2-8f84-5a11e4c0afee-01-en",
      },
    },
  },
  //机器视觉
  {
    id: 8,
    title: "Security Applications",
    keyFeatures: [],
    features: [
      {
        info: "Safety Helmet/Vest Detection",
        subInfo: [],
      },
      {
        info: "Flame Detection",
        subInfo: [],
      },
      {
        info: "Face Detection",
        subInfo: [],
      },
      {
        info: "Person Detection",
        subInfo: [],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "46fa6615-ab74-4df3-a6c4-3050338244e6-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "",
      },
    },
  },
  {
    id: 9,
    title: "Industrial Inspection",
    keyFeatures: [],
    features: [
      {
        info: "Cosmetic Defects Detection",
        subInfo: ["High Precision", "Stability", "Real-Time Application"],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "0e16b67f-853e-4571-9139-123231f4298f-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "",
      },
    },
  },
  {
    id: 10,
    title: "TurMass™ Terminal",
    keyFeatures: [
      "Wide Temperature Applications",
      "Low Power WAN",
      "National Solutions",
    ],
    features: [
      {
        info: "Module design by TaoLink",
        subInfo: [],
      },
      {
        info: "Free band",
        subInfo: [],
      },
      {
        info: "Encrypted transmission",
        subInfo: [],
      },
      {
        info: "Shell:IP65 waterproof and dustproof",
        subInfo: [],
      },
      {
        info: "Core: RISC-V",
        subInfo: [],
      },
      {
        info: "Operating temperature:-40°C~85°C",
        subInfo: [],
      },
      {
        info: "note:TurMass™ is a trademark of Shanghai TAOLINK Technology Co.",
        subInfo: [],
      },
    ],
    name: "gateway",
    details: [
      // {
      //   input: `Internal battery power | External DC power`,
      //   size: "133mm * 75.89mm * 48.09mm",
      //   fre: "Low frequency (470MHz~510MHz) or high frequency (863MHz~928MHz)",
      //   rate: "0.441kbps~85.106kbps",
      //   // distance: "Outdoor test: 4.2 kilometres",
      // },
      {
        // input: `内部电池供电或外部直流供电`,
        type: "with shell",
        PN: "<div>ZZHY2024110705 | ZZHY2024110705F</div>",
        fre: "low(470MHz~510MHz)or high(863MHz~928MHz)",
        size: "133mm * 75.89mm * 48.09mm",
        // rate: "0.441kbps~85.106kbps",
        // distance: "户外测试:4.2 千米",
        download: "ba14e59a-cff2-1234-99a5-19310964837b-01-en",
      },
      {
        // input: `内部电池供电或外部直流供电`,
        type: "without shell",
        PN: "<div>ZZHY2024111801 | ZZHY2024111801F</div>",
        fre: "low(470MHz~510MHz)or high(863MHz~928MHz)",
        size: "30mm * 30mm * 11.7mm",

        // rate: "0.441kbps~85.106kbps",
        // distance: "户外测试:4.2 千米",
        download: "ba14e59a-cff2-1234-66a5-19310964837b-01-en",
      },
    ],
    img: [
      {
        src: prefix + "52672361-76cf-487a-9889-8f7f80ee1b3c-03-xx.png",
        // src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "ba14e59a-cff2-1234-99a5-19310964837b-01-en",
    //   },
    // },
  },
  {
    id: 11,
    title: "TurMass™-4G Gateway",
    keyFeatures: [
      "Heterogeneous Collaboration",
      "National Solutions",
      "Wide Temperature Applications ",
    ],
    features: [
      {
        info: "Module design by TaoLink",
        subInfo: [],
      },
      {
        info: "Operating temperature:-30­°C~75­°C",
        subInfo: [],
      },
      {
        info: "Encrypted transmission",
        subInfo: [],
      },
      {
        info: "Shell:IP65 waterproof and dustproof",
        subInfo: [],
      },
      {
        info: "Positioning: BeiDou/GPS/GLONASS/QZSS",
        subInfo: [],
      },
      {
        info: "support customised international IoT card",
        subInfo: [],
      },
      {
        info: "note:TurMass™ is a trademark of Shanghai TAOLINK Technology Co.",
        subInfo: [],
      },
    ],
    name: "gateway",
    details: [
      // {
      //   input: `external power supply（5~15V）`,
      //   size: "133mm * 75.89mm * 48.09mm",
      //   fre: `4G：LTE-TDD：B34/B38/B39/B40/B41
      //   TurMass：Low frequency (470MHz~510MHz) or high frequency`,
      // },
      {
        input: `external power supply（5~15V）`,
        PN: "<div>ZZHY2024110708 | ZZHY2024110708F</div>",
        size: "133mm * 75.89mm * 48.09mm",
        fre: "<div>4G：LTE-TDD：B34/B38/B39/B40/B41</div><div>TurMass：Low frequency (470MHz~510MHz) or high frequency</div>",
        download: "ba14e59a-cdd2-6666-99a5-19310964837b-01-en",
      },
    ],
    img: [
      {
        src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896www-03-xx.png",
        // src: prefix + "3c2bwe13-b963-43c4-a8a0-73c324896e10-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "ba14e59a-cdd2-6666-99a5-19310964837b-01-en",
    //   },
    // },
  },
  {
    id: 12,
    title: "LoRa-Ethernet Gateway",
    keyFeatures: [
      "Heterogeneous Collaboration",
      "Free Networking",
      "Wide Temperature Applications ",
    ],
    features: [
      {
        info: "Low band or high band",
        subInfo: [],
      },
      {
        info: "Shell:IP65 waterproof and dustproof",
        subInfo: [],
      },
      {
        info: "Encrypted transmission",
        subInfo: [],
      },
      {
        info: "Embedded real-time operating system",
        subInfo: [],
      },
    ],
    name: "gateway",
    details: [
      {
        input: `AC:220VAC`,
        PN: "<div>ZZHY2024110709 | ZZHY2024110709F</div>",
        size: "133mm * 75.89mm * 48.09mm",
        netrate: "10/100M",
        fre: "LoRa：410M～525MHz or 860～930MHz",
        LoRa_Receive_Sensitivity: "LoRa：-137 dBm@SF10 125KHz",
        download: "sn14e59a-cff2-6666-99a5-19310964837b-01-en",
      },
    ],
    img: [
      {
        src: prefix + "449bwe13-b963-43c4-a8a0-73c324566e10-03-xx.png",
      },
    ],
    // download: {
    //   cn: {
    //     file_code: "sn14e59a-cff2-6666-99a5-19310964837b-01-en",
    //   },
    // },
  },
];

export var serviceData_en = [
  {
    id: 1,
    title: "Circuit Design",
    keyFeatures: [],
    features: [
      {
        info: "Analog/Digital Circuit Design, PCB Layout, Prototyping Production, including:",
        subInfo: [
          "Embedded System (Including communication module)",
          "AC/DC power supply, DC/DC power supply",
          "Lithium battery energy storage system",
        ],
      },
      {
        info: "3D system modeling",
        subInfo: [],
      },
      {
        info: "Cost-effective, mass-producible BOM materials",
        subInfo: [],
      },
      {
        info: "Third party certification services(EMC, Safety and Reliability)",
        subInfo: [],
      },
      {
        info: "High density, compact design",
        subInfo: [],
      },
    ],
    name: "dianlu",
    details: [],
    img: [
      {
        src: prefix + "5b46c30d-4b0b-410b-b824-1281e3112aba-03-xx.jpg",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-en",
      },
    },
  },
  {
    id: 2,
    title: "Embedded Software Development",
    keyFeatures: [],
    features: [
      {
        info: "ARM Cortex-M series (e.g., STM32), including：",
        subInfo: [
          "ADC/DAC",
          "UART,I2C,SPI,CAN,RS-232,RS-485,U SB and other bus communication",
          "Real-time operating system FreeRTOS, RT-Thread, etc.",
          "FATFS file system",
        ],
      },
      {
        info: "ARM Cortex-A Series",
        subInfo: [],
      },
      {
        info: "Battery BMS algorithm",
        subInfo: [],
      },
      {
        info: "Lightweight deep neural network development",
        subInfo: [],
      },
      {
        info: "Over-the-Air Firmware Upgrade(FOTA)",
        subInfo: [],
      },
    ],
    name: "neptune",
    details: [],
    img: [
      {
        src: prefix + "6faa16f6-bf6f-483d-afc1-6c39ef1261a9-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-en",
      },
    },
  },
  {
    id: 3,
    title: "Mechanical design",
    keyFeatures: [],
    features: [
      {
        info: "ID Design",
        subInfo: [],
      },
      {
        info: "Complete machine design",
        subInfo: [],
      },
      {
        info: "2D/3D structural drawings",
        subInfo: [],
      },
      {
        info: "Stacking",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "c747d053-0baf-425e-8202-a72999054e6e-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "c51f5f07-d50d-4ea4-aa28-4b47524d7a10-01-en",
      },
    },
  },
  {
    id: 4,
    title: "Desktop Application Development",
    keyFeatures: [
      "Cross Platform",
      "Multiple Communication Protocols BLE, WIFI, etc.",
      "Real-time Waveform Graph",
    ],
    features: [
      {
        info: "Cross-platform (Windows, Linux, MacOs)",
        subInfo: [],
      },
      {
        info: "AI Algorithm",
        subInfo: [],
      },
      {
        info: "Free addition and subtraction of modules",
        subInfo: [],
      },
      {
        info: "Adaptive Layout",
        subInfo: [],
      },
      {
        info: "Multiple Communication Protocols BLE, WIFI",
        subInfo: [],
      },
      {
        info: "Embedded Database",
        subInfo: [],
      },
      {
        info: "Exquisite UI Design",
        subInfo: [],
      },
      {
        info: "Automatic Report Generation ",
        subInfo: [],
      },
      {
        info: "OTA Upgrade",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "777c112b-83ca-4acf-8a98-4947ab5524da-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "91dc746c-3c01-437e-a6e1-e1ddc932d9ce-01-en",
      },
    },
  },
  {
    id: 5,
    title: "Front-end Development",
    keyFeatures: [
      "Front-end Development",
      "Big Data Screen",
      "Professional UI Design",
    ],
    features: [
      {
        info: "Real-time",
        subInfo: [],
      },
      {
        info: "Omni-directional",
        subInfo: [],
      },
      {
        info: "Compatibility",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "7d0dd728-23fa-4e01-9e94-234809b8be78-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "a73372b9-7df0-46bc-b989-00131aa87380-01-en",
      },
    },
  },
  {
    id: 6,
    title: "Data Analysis",
    keyFeatures: [],
    features: [
      {
        info: "Mathematical Statistics",
        subInfo: [],
      },
      {
        info: "Data Modeling",
        subInfo: [],
      },
      {
        info: "Data Mining",
        subInfo: [],
      },
      {
        info: "Machine Learning",
        subInfo: [],
      },
      {
        info: "Big Data Analysis",
        subInfo: [],
      },
    ],
    name: "IOTsystem",
    details: [],
    img: [
      {
        src: prefix + "390147f2-6115-42d6-9be8-0300ddb473ef-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "a73372b9-7df0-46bc-b989-00131aa87380-01-cn",
      },
    },
  },
  {
    id: 7,
    title: "Edge Computing Gateway",
    keyFeatures: [
      "AI computing",
      "Edge-cloud Collaboration",
      "High Reliability",
    ],
    features: [
      {
        info: "AI algorithm",
        subInfo: [],
      },
      {
        info: "Linux system",
        subInfo: [],
      },
      {
        info: "Support mainstream target detection and image recognition algorithms",
        subInfo: [],
      },
      {
        info: "Algorithmic deliveried from Cloud",
        subInfo: [],
      },
      {
        info: "4G、5G、Zigbee、LoRa",
        subInfo: [],
      },
      {
        info: "Supports the industry's leading deep learning computing frameworks",
        subInfo: [],
      },
      {
        info: "Support for Docker Deployment",
        subInfo: [],
      },
      {
        info: "Task scheduling",
        subInfo: [],
      },
    ],
    name: "",
    details: [],
    img: [
      {
        src: prefix + "4c40bf6d-c04e-4249-a032-e46e211www3c-03-xx.png",
      },
    ],
    download: {
      cn: {
        file_code: "0e9040b9-1a64-4982-a246-91356ed928b9-01-en",
      },
    },
  },
];

export const getProductByName = function (name, en_or_cn = "cn") {
  if (en_or_cn === "cn")
    return productData.find((element) => element.title === name);
  if (en_or_cn === "en")
    return productData_en.find((element) => element.title === name);
};
